import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idle-time',
  templateUrl: './idle-time.component.html',
  styleUrls: ['./idle-time.component.scss']
})
export class IdleTimeComponent implements OnInit {
  public countDown: any;

  constructor() { }

  ngOnInit(): void {
    console.log("popped")
  }
}

