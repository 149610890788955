import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../common-services/data.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from "ag-grid-community";
import { UtilityService } from '../common-services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from "@angular/common";
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariableService } from '../common-services/global-variable.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {

  @ViewChild('agGrid') agGrid: AgGridAngular;

  public selectedReportType: any = 'report-for-business';
  public yearData: any = []
  public selectedYears: any = []
  public selectedIndustryId: any = [];
  public selectedBusinessId: any = [];
  public industryData: any = [];
  public businessDropdownData: any = [];
  public fetchingData: boolean;
  public res: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public gridOptions: Partial<GridOptions>;
  public paginationPageSize: number = 100;
  public showTable: boolean = false;
  public presentYear: any;
  public fetchReportResponse: any;
  public presentYearString: any;
  public reportIndustries: any;
  public multipleBusinessIndustry: any = [];
  public singleBusinessIndustry: any = []
  public IndustryArray: any = []
  public allIndustriesSelected: boolean = false;
  public allBusinessSelected: boolean = false;
  public errorMsg: string;
  public errorDiv: boolean = false;


  constructor(
    private dataService: DataService,
    private utilService: UtilityService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private globalService: GlobalVariableService,
  ) { }
  // ag grid table column name and its properties declaration
  public defaultColDef: any = {
    editable: false, // setting this as true
    floatingFilter: true,
    resizable: true,
    sortable: true,
    filter: true,
  };

  public columnDef: any = [];

  public columnDefsCompany = [
    {
      headerName: "Year",
      field: 'archive_year',
      sortable: true,
      filter: true,
      width: 100,
      lockPosition: true,
      lockVisible: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_0",
      field: 'company_name',
      width: 100,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Name", field: 'riskarea_name', sortable: true, filter: true, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Code", field: 'riskarea_code', sortable: true, filter: true, width: 120, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Mode", field: 'riskarea_mode', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      cellStyle: (params: any) => {
        if (params.value === 'Override') {
          return { color: 'red' };
        }
        return null;
      }
    },
    {
      headerName: "Inherent Position", field: 'override_inherent', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Control Position", field: 'override_control', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Residual Score", field: 'override_residual', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Rating", field: 'rating_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Control Rating", field: 'rating_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Residual Rating", field: 'rating_residual', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Auto - Inherent Position", field: 'auto_inherent', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Control Position", field: 'auto_control', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Residual Score", field: 'auto_residual', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Updated By", field: 'updated_by', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated Date", field: 'updated_at', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? (formatDate(params.value, 'dd-MM-yyyy', 'en-US')) : 'N/A')
      }
    },
  ];

  public columnDefsIndustry = [
    {
      headerName: "Year",
      field: 'archive_year',
      sortable: true,
      filter: true,
      width: 100,
      lockPosition: true,
      lockVisible: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_0",
      field: 'company_name',
      width: 100,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_1",
      field: 'industry_name',
      width: 140,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_1 WeightagePCT",
      field: 'industry_weightage',
      width: 110,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Name", field: 'riskarea_name', sortable: true, filter: true, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Code", field: 'riskarea_code', sortable: true, filter: true, width: 120, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Mode", field: 'riskarea_mode', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      cellStyle: (params: any) => {
        if (params.value === 'Override') {
          return { color: 'red' };
        }
        return null;
      }
    },
    {
      headerName: "Inherent Position", field: 'override_inherent', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Control Position", field: 'override_control', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Residual Score", field: 'override_residual', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Rating", field: 'rating_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Control Rating", field: 'rating_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Residual Rating", field: 'rating_residual', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Auto - Inherent Position", field: 'auto_inherent', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Control Position", field: 'auto_control', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Residual Score", field: 'auto_residual', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Drivers", field: 'drivers_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Control Drivers", field: 'drivers_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Other Drivers", field: 'drivers_others', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated By", field: 'updated_by', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated Date", field: 'updated_at', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? (formatDate(params.value, 'dd-MM-yyyy', 'en-US')) : 'N/A')
      }
    },
  ];

  public columnDefsBusiness = [
    {
      headerName: "Year",
      field: 'archive_year',
      sortable: true,
      filter: true,
      width: 100,
      lockPosition: true,
      lockVisible: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_0",
      field: 'company_name',
      width: 100,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_1",
      field: 'industry_name',
      width: 140,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_2",
      field: 'business_name',
      width: 140,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_2 WeightagePCT",
      field: 'business_weightage',
      width: 110,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Name", field: 'riskarea_name', sortable: true, filter: true, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Code", field: 'riskarea_code', sortable: true, filter: true, width: 120, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Mode", field: 'riskarea_mode', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      cellStyle: (params: any) => {
        if (params.value === 'Override') {
          return { color: 'red' };
        }
        return null;
      }
    },
    {
      headerName: "Inherent Position", field: 'override_inherent', sortable: true, filter: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      width: 140,
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Control Position", field: 'override_control', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Residual Score", field: 'override_residual', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Rating", field: 'rating_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Control Rating", field: 'rating_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Residual Rating", field: 'rating_residual', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Auto - Inherent Position", field: 'auto_inherent', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Control Position", field: 'auto_control', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Residual Score", field: 'auto_residual', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Drivers", field: 'drivers_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },

    },
    {
      headerName: "Control Drivers", field: 'drivers_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Other Drivers", field: 'drivers_others', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated By", field: 'updated_by', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated Date", field: 'updated_at', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? (formatDate(params.value, 'dd-MM-yyyy', 'en-US')) : 'N/A')
      }
    },
  ];

  public columnDefsBusinessSubRisks = [
    {
      headerName: "Year",
      field: 'archive_year',
      sortable: true,
      filter: true,
      width: 100,
      lockPosition: true,
      lockVisible: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_0",
      field: 'company_name',
      width: 100,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_1",
      field: 'industry_name',
      width: 140,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_2",
      field: 'business_name',
      width: 140,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Tier_2 WeightagePCT",
      field: 'business_weightage',
      width: 110,
      sortable: true,
      filter: true,
      resizable: true,
      autoHeight: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Name", field: 'riskarea_name', sortable: true, filter: true, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Risk Code", field: 'riskarea_code', sortable: true, filter: true, width: 120, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Mode", field: 'riskarea_mode', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      cellStyle: (params: any) => {
        if (params.value === 'Override') {
          return { color: 'red' };
        }
        return null;
      }
    },
    {
      headerName: "Inherent Position", field: 'override_inherent', sortable: true, filter: true,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      width: 140,
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Control Position", field: 'override_control', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Residual Score", field: 'override_residual', sortable: true, filter: true, width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Rating", field: 'rating_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Control Rating", field: 'rating_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Residual Rating", field: 'rating_residual', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
    },
    {
      headerName: "Auto - Inherent Position", field: 'auto_inherent', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Control Position", field: 'auto_control', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - Residual Score", field: 'auto_residual', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Inherent Drivers", field: 'drivers_inherent', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Control Drivers", field: 'drivers_control', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Other Drivers", field: 'drivers_others', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Area", field: 'subrisk_name', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Required", field: 'subrisk_required', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Weighting", field: 'subrisk_weightage', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Likelihood Rating", field: 'subrisk_inherent_likelihood', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "SR Severity Rating", field: 'subrisk_inherent_severity', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - SR Inherent Score", field: 'subrisk_inherent_auto_score', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Final - SR Inherent Score", field: 'subrisk_inherent_final_score', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "SR Mode - Inherent", field: 'subrisk_inherent_mode', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Inherent Rating", field: 'subrisk_inherent_rating', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Control Rating", field: 'subrisk_control_rating', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Auto - SR Control Score", field: 'subrisk_control_auto_score', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "Final - SR Control Score", field: 'subrisk_control_final_score', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "SR Mode - Control", field: 'subrisk_control_mode', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Control Effectiveness Rating", field: 'subrisk_control_effectiveness_rating', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Residual Score", field: 'subrisk_residual_score', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return params.value != 'null' ? params.value.toFixed(2) : 'N/A'
      }
    },
    {
      headerName: "SR Residual Risk", field: 'subrisk_residual_rating', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "SR Notes", field: 'subrisk_notes', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Control List", field: 'subrisk_control_list', sortable: true, filter: true, width: 140, filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated By", field: 'updated_by', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? params.value : 'N/A')
      }
    },
    {
      headerName: "Updated Date", field: 'updated_at', sortable: true, filter: true,
      width: 140,
      filterParams: {
        filterOptions: ['equals', 'contains', 'notContains', 'startsWith', 'endsWith'],
        defaultOption: 'contains',
        suppressAndOrCondition: true
      },
      valueFormatter: (params: any) => {
        return (params.value != null ? (formatDate(params.value, 'dd-MM-yyyy', 'en-US')) : 'N/A')
      }
    },
  ];

  ngOnInit(): void {
    this.showTable = false;
    this.errorDiv = false
    this.presentYear = new Date().getFullYear()
    this.presentYearString = new Date().getFullYear().toString()
    this.selectedYears = [this.presentYearString]
    this.getAllIndustries()
    this.getGraphsIndustrywise();
    this.fetchyearsForReport()
    this.fetchIndustriesForReport();
    this.generateGridOptions();
  }

  // getting all industries and its business
  getGraphsIndustrywise() {
    this.dataService.getGraphsIndustrywise().subscribe((data: any) => {
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].businesses.length > 1) {
            this.multipleBusinessIndustry.push(data[i].name);
          }
          else {
            this.singleBusinessIndustry.push(data[i].name);
          }
        }
      }
    })
  }

  // getting all industries
  getAllIndustries() {
    this.dataService.industriesGetRequest().subscribe((res: any) => {
      if (res.length > 0) {
        this.reportIndustries = res
      }
    }, (error: any) => {
      this.toastr.error(error, "error")
    })
  }

  //getting all archives years
  fetchyearsForReport() {
    this.dataService.archiveYearsGetRequest().subscribe((res: any) => {
      if (res) {
        this.yearData = res
      }
    })
  }

  Unique(value: any, args?: any) {
    // Remove the duplicate elements
    const uniqueArray = value.filter((el: any, index: any, array: any) => {
      return array.indexOf(el) === index;
    });
    return this.businessDropdownData = uniqueArray;
  }

  fetchIndustriesForReport() {
    if (this.globalService.industryDetails.length > 0) {
      this.industryData = this.globalService.industryDetails;
    }
    else {
      this.toastr.error('Failed to fetch Industries', "error")
    }
  }

  //fetching business according to industry selected in industry selection field
  fetchGraphByIndustryId(event: any) {
    this.businessDropdownData = []
    this.selectedBusinessId = []
    this.allBusinessSelected = false
    var businessArray: any = [];

    if (event == 0) {
      if (!this.allIndustriesSelected) {
        this.allIndustriesSelected = true
        this.IndustryArray.push(0)
        this.industryData.forEach((element: any) => {
          this.IndustryArray.push(element.industryId)
        });
        this.selectedIndustryId = this.IndustryArray
      }
      else {
        this.allIndustriesSelected = false
        this.IndustryArray = []
        this.selectedIndustryId = []
      }
    }
    else {
      if (this.IndustryArray.length == 0) {
        this.IndustryArray.push(event)
      }
      else if (this.IndustryArray.length > 0) {
        if (this.IndustryArray.filter((val: any) => val == event).length > 0) {
          let index = this.IndustryArray.findIndex((x: any) => x == event);
          this.IndustryArray.splice(index, 1);
        } else {
          this.IndustryArray.push(event)
        }
      }
    }

    this.fetchingData = true;
    let count = 0

    if (this.IndustryArray.length == 0) {
      this.fetchingData = false
    }

    this.IndustryArray.forEach((id: any) => {
      if (id != 0) {
        this.industryData.forEach((element: any) => {
          if (id == element.industryId) {
            element.businesses.forEach((business: any) => {
              businessArray.push(business);
            })
            this.fetchingData = false;
          }
          else {
            this.fetchingData = false;
          }
        })
      }
    });
    this.Unique(businessArray);
  }

  //formating business accordingly
  formatBusinessIdArray(event: any) {
    if (event == 0) {
      if (!this.allBusinessSelected) {
        this.allBusinessSelected = true
        this.selectedBusinessId.push(0);
        this.businessDropdownData.forEach((val: any) => {
          this.selectedBusinessId.push(val.businessId)
        })
      } else {
        this.allBusinessSelected = false
        this.selectedBusinessId = []
      }
    }
  }

  //fetch report button click
  getCompanyData(type: string, payload: any) {
    this.errorDiv = false
    if (type == 'report-for-industry' && this.selectedIndustryId.length == 0) {
      this.toastr.warning("Please select at least one option", "Warning")
    } else if (type == 'report-for-business' && (this.selectedIndustryId.length == 0 || this.selectedBusinessId.length == 0)) {
      this.toastr.warning("Please select at least one option", "Warning")
    } else if (type == 'report-for-business-subrisk' && (this.selectedIndustryId.length == 0 || this.selectedBusinessId.length == 0)) {
      this.toastr.warning("Please select at least one option", "Warning")
    } else {
      this.spinner.show();
      this.showTable = false
      let load = {};
      switch (type) {

        case 'report-for-company':
          load = { years: payload };
          this.columnDef = this.columnDefsCompany;
          this.fetchReport(type, load);
          break;

        case 'report-for-industry':
          load = { years: payload, industries: this.selectedIndustryId };
          this.columnDef = this.columnDefsIndustry;
          this.fetchReport(type, load);
          break;

        case 'report-for-business':
          load = { years: payload, industries: this.selectedIndustryId, businesses: this.selectedBusinessId };
          this.columnDef = this.columnDefsBusiness;
          this.fetchReport(type, load);
          break;

        case 'report-for-business-subrisk':
          load = { years: payload, industries: this.selectedIndustryId, businesses: this.selectedBusinessId };
          this.columnDef = this.columnDefsBusinessSubRisks;
          this.fetchReport(type, load);
          break;
      }
    }
  }

  onGridReady(params?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  generateGridOptions() {
    this.gridOptions = {
      pagination: true,
      paginationPageSize: 50,
    }
  }

  onPageSizeChanged(event: any) {
    var E = event.target.value;
    this.gridApi.paginationSetPageSize(E);
  }

  // fetching report according to report type, the resultant api data will distribute accordinly with help of switch
  fetchReport(type: any, payLoad: any) {
    this.res = []
    let currentYearData: any;
    let archivedYearData: any = [];
    let found = this.selectedYears.find((element: any) => {
      return element === this.presentYearString;
    });
    this.dataService.reportsTypePost(type, payLoad).subscribe((response: any) => {
      this.fetchReportResponse = response;
      this.spinner.hide();
      if (Object.keys(response).filter((val: any) => val == 'currentYear').length > 0) {
        this.showTable = true;
        this.errorDiv = false;
        if (Object.keys(response.currentYear)?.length > 0) {
          if (response.archiveYear?.length > 0) {
            archivedYearData = response.archiveYear
          }
          currentYearData = [response.currentYear]
          switch (type) {

            case 'report-for-company':
              if (archivedYearData.length > 0) {
                this.formatCompanyArchiveData(archivedYearData);
              }
              this.getCompanyDataReportFormat(found, currentYearData)
              break;

            case 'report-for-industry':
              if (archivedYearData.length > 0) {
                this.formatIndustryArchiveData(archivedYearData)
              }
              this.getIndustriesDataReportFormat(currentYearData)
              break;

            case 'report-for-business':
              if (archivedYearData.length > 0) {
                this.formatBusinessArchiveData(archivedYearData)
              }
              this.getBusinessDataReportFormat(found, currentYearData)
              break;

            case 'report-for-business-subrisk':
              if (archivedYearData.length > 0) {
                this.formatBusinessSubRiskArchiveData(archivedYearData)
              }
              this.getBusinessSubriskDataReportFormat(found, currentYearData)
              break;
          }

        } else {
          switch (type) {

            case 'report-for-company':
              if (response.archiveYear.length > 0) {
                this.formatCompanyArchiveData(response.archiveYear);
              }
              break;

            case 'report-for-industry':
              if (response.archiveYear.length > 0) {
                this.formatIndustryArchiveData(response.archiveYear)
              }
              break;

            case 'report-for-business':
              if (response.archiveYear.length > 0) {
                this.formatBusinessArchiveData(response.archiveYear)
              }
              break;

            case 'report-for-business-subrisk':
              if (response.archiveYear.length > 0) {
                this.formatBusinessSubRiskArchiveData(response.archiveYear)
              }
              break;
          }
          this.gridApi.setRowData(this.res);
        }
      } else if (Object.keys(response).filter((val: any) => val == "errorMessage").length > 0) {
        this.errorDiv = true;
        if (response.errorType == "Function.ResponseSizeTooLarge") {
          this.errorMsg = "The Report criteria caused the report creation to error out. Please reduce your criteria (e.g. number of years) and try again.", "Error"
        } else {
          this.errorMsg = response.errorMessage
        }
      }
    },
      (error: any) => {
      });
  }

  //formating company data table for archived data
  formatCompanyArchiveData(array: any) {
    array.forEach((item: any) => {
      let obj = {
        archive_year: item.archive_year,
        company_name: item.company_name,
        riskarea_name: item.riskarea_name,
        riskarea_code: item.riskarea_code,
        riskarea_mode: item.riskarea_mode,
        override_inherent: item.override_inherent,
        override_control: item.override_control,
        override_residual: item.override_residual,
        rating_inherent: item.rating_inherent,
        rating_control: item.rating_control,
        rating_residual: item.rating_residual,
        auto_inherent: item.auto_inherent,
        auto_control: item.auto_control,
        auto_residual: item.auto_residual,
        updated_by: item.updated_by,
        updated_at: item.updated_at
      }
      this.res.push(obj)
    });
  }

  //formating industry data table for archived data
  formatIndustryArchiveData(array: any) {
    array.forEach((item: any) => {
      let obj = {
        archive_year: item.archive_year,
        company_name: item.company_name,
        industry_name: item.industry_name,
        industry_weightage: item.industry_weightage.toString() + "%",
        riskarea_name: item.riskarea_name,
        riskarea_code: item.riskarea_code,
        riskarea_mode: item.riskarea_mode,
        override_inherent: item.override_inherent,
        override_control: item.override_control,
        override_residual: item.override_residual,
        rating_inherent: item.rating_inherent,
        rating_control: item.rating_control,
        rating_residual: item.rating_residual,
        drivers_inherent: item.inherent_drivers,
        drivers_control: item.control_drivers,
        drivers_others: item.other_drivers,
        auto_inherent: item.auto_inherent,
        auto_control: item.auto_control,
        auto_residual: item.auto_residual,
        updated_by: item.updated_by,
        updated_at: item.updated_at
      }
      this.res.push(obj)
    });
  }

  //formating business data table for archived data
  formatBusinessArchiveData(array: any) {
    array.forEach((item: any) => {
      let obj = {
        archive_year: item.archive_year,
        company_name: item.company_name,
        industry_name: item.industry_name,
        business_name: item.business_name,
        business_weightage: item.business_weightage.toString() + "%",
        riskarea_name: item.riskarea_name,
        riskarea_code: item.riskarea_code,
        riskarea_mode: item.riskarea_mode,
        override_inherent: item.override_inherent,
        override_control: item.override_control,
        override_residual: item.override_residual,
        rating_inherent: item.rating_inherent,
        rating_control: item.rating_control,
        rating_residual: item.rating_residual,
        auto_inherent: item.auto_inherent,
        auto_control: item.auto_control,
        auto_residual: item.auto_residual,
        drivers_inherent: item.drivers_inherent,
        drivers_control: item.drivers_control,
        drivers_others: item.drivers_others,
        updated_by: item.updated_by,
        updated_at: item.updated_at
      }
      this.res.push(obj)
    });
  }

  //formating business+subrisk data table for archived data
  formatBusinessSubRiskArchiveData(array: any) {
    array.forEach((item: any) => {
      let obj = {
        archive_year: item.archive_year,
        company_name: item.company_name,
        industry_name: item.industry_name,
        business_name: item.business_name,
        business_weightage: item.business_weightage.toString() + "%",
        riskarea_name: item.riskarea_name,
        riskarea_code: item.riskarea_code,
        riskarea_mode: item.riskarea_mode,
        override_inherent: item.override_inherent,
        override_control: item.override_control,
        override_residual: item.override_residual,
        rating_inherent: item.rating_inherent,
        rating_control: item.rating_control,
        rating_residual: item.rating_residual,
        auto_inherent: item.auto_inherent,
        auto_control: item.auto_control,
        auto_residual: item.auto_residual,
        drivers_inherent: item.drivers_inherent,
        drivers_control: item.drivers_control,
        drivers_others: item.drivers_others,
        subrisk_name: item.subrisk_name,
        subrisk_required: this.subriskType(item.is_mandatory),
        subrisk_weightage: item.subrisk_weightage.toString() + "%",
        subrisk_inherent_likelihood: item.subrisk_inherent_likelihood,
        subrisk_inherent_severity: item.subrisk_inherent_severity,
        subrisk_inherent_auto_score: item.subrisk_inherent_auto_score,
        subrisk_inherent_final_score: item.subrisk_inherent_final_score,
        subrisk_inherent_mode: item.subrisk_inherent_mode,
        subrisk_inherent_rating: item.subrisk_inherent_rating,
        subrisk_control_rating: item.subrisk_control_rating,
        subrisk_control_auto_score: item.subrisk_control_auto_score,
        subrisk_control_final_score: item.subrisk_control_final_score,
        subrisk_control_mode: item.subrisk_control_mode,
        subrisk_control_effectiveness_rating: item.subrisk_control_effectiveness_rating,
        subrisk_residual_score: item.subrisk_residual_score,
        subrisk_residual_rating: item.subrisk_residual_rating,
        subrisk_notes: item.subrisk_notes,
        subrisk_control_list: item.subrisk_control_list,
        updated_by: item.updated_by,
        updated_at: item.updated_at
      }
      this.res.push(obj)
    });
  }

  //formating company data table for present year data
  getCompanyDataReportFormat(found: any, currentYearData: any) {
    let resMidArray: any = []
    let csvArr: any = [];
    if (currentYearData[0].data[this.presentYear] && Object.keys(currentYearData[0].data[this.presentYear]).length > 0) {
      currentYearData.forEach((data: any, index: any) => {
        if (found === Object.keys(data.data)[0]) {
          let industryMergeArr: any = [];
          let industryWtObj: any = [];
          let industryOverrideData: any = [];
          data.data[this.presentYear].forEach((industry: any) => {
            let array: any = [];
            var graphWeightObj: any = {};
            industry.businesses.forEach((business: any) => {
              var graph = business.graph;
              if (graph != null) {
                var nodes = graph.nodes;
                nodes = nodes.filter((obj: any) => {
                  obj['t2name'] = graph.name;
                  return obj;
                });

                nodes.sort((a: any, b: any) => {
                  return a.nodeid - b.nodeid
                });

                graph.nodes.forEach((node: any) => {
                  node['industryId'] = industry.id;
                });

                array.push(nodes);
                graphWeightObj[graph.id] = graph.weightage;
              }
            });

            if (array.length > 0) {
              industryWtObj.push(industry.weightPercent);

              if (typeof (industry.IndustryOverrides) !== 'undefined') {
                industry.IndustryOverrides.forEach((industryOverride: any) => {
                  industryOverrideData.push(industryOverride);
                });
              }
              industryMergeArr.push(this.utilService.mergeNodeArrays(array, graphWeightObj, undefined));
            }
          });
          resMidArray = this.utilService.mergeNodeArraysCompany(industryMergeArr, industryWtObj, industryOverrideData);
          resMidArray.map((item: any) => {
            var searchNode = currentYearData[0].overriden[this.presentYear].find((n: any) => {
              return n.nodeId === item.nodeid;
            });

            if (typeof (searchNode) !== 'undefined') {
              item.overridenX = searchNode.newLocationX;
              item.overridenY = searchNode.newLocationY;
            }
          });
        }
      });
    }
    resMidArray.forEach((dataItem: any) => {
      csvArr = this.reportGameBoardFormat(csvArr, dataItem, this.presentYearString);
    });
    if (csvArr?.length > 0) {
      csvArr.map((item: any) => this.res.push(item))
      this.res.sort((a: any, b: any) => {
        return parseInt(b.archive_year) - parseInt(a.archive_year)
      });
      this.gridApi.setRowData(this.res);
    }
  }

  //formating industry data table for present year data
  getIndustriesDataReportFormat(currentYearData: any) {
    let reportGraphNodes: any = [];
    if (currentYearData[0].data[this.presentYear] && Object.keys(currentYearData[0].data[this.presentYear]).length > 0) {
      Object.entries(currentYearData[0].data[this.presentYear]).forEach((entry: any) => {
        const [id, industry] = entry;
        if (id != 0) {
          var array: any = [];
          var graphWeightObj: any = {};
          var findIndustry: any = this.reportIndustries.find((element: any) => {
            return element.id == id;
          });
          Object.values(industry).forEach((data: any) => {
            var nodes = data.nodes;
            nodes = nodes.filter((obj: any) => {
              obj['t2name'] = data.name;
              obj['t1Weight'] = findIndustry.weightPercent;
              return obj;
            });
            nodes.sort((a: any, b: any) => {
              return a.nodeid - b.nodeid
            });
            array.push(nodes);
            graphWeightObj[data.id] = data.weightage;
          });
          reportGraphNodes[findIndustry.name] = this.utilService.mergeNodeArrays(array, graphWeightObj, undefined);
        }
      });
    }
    var csvArr: any = [];
    Object.entries(reportGraphNodes).forEach((entry: any) => {
      const [industryName, industries] = entry;
      industries.forEach((item: any) => {
        csvArr = this.reportGameBoardFormat(csvArr, item, this.presentYearString, industryName);
      });
    });
    if (csvArr?.length > 0) {
      csvArr.map((item: any) => this.res.push(item))
      this.res.sort((a: any, b: any) => {
        return parseInt(b.archive_year) - parseInt(a.archive_year)
      });
      this.gridApi.setRowData(this.res);
    }

  }

  //formating business data table for present year data
  getBusinessDataReportFormat(found: any, currentYearData: any) {
    let reportGraphNodes: any = [];
    if (currentYearData[0][this.presentYear] && Object.keys(currentYearData[0][this.presentYear]).length > 0) {
      Object.entries(currentYearData[0][this.presentYear]).forEach((entry: any) => {
        const [id, business] = entry;
        Object.values(business).forEach((data: any) => {
          var nodes = data.nodes
          nodes = nodes.filter((obj: any) => {
            obj['t2Weight'] = data.weightage;
            return obj;
          });
          nodes.sort((a: any, b: any) => {
            return a.nodeid - b.nodeid
          });
          if (!reportGraphNodes[data.business.industry.name]) {
            reportGraphNodes[data.business.industry.name] = {};
          }
          let a = reportGraphNodes[data.business.industry.name]
          reportGraphNodes[data.business.industry.name][data.business.name] = nodes;
        });

      });
      var csvArr: any = [];
      let reportBusinessnames = [];
      Object.entries(reportGraphNodes).forEach((entry: any) => {
        const [industryName, industries] = entry;
        Object.entries(industries).forEach((entry2: any) => {
          const [BusinessIndex, businesses] = entry2;
          reportBusinessnames.push(BusinessIndex);
          businesses.forEach((item: any) => {
            csvArr = this.reportGameBoardFormat(csvArr, item, this.presentYearString, industryName, BusinessIndex);
          });
        });
      });
    }
    if (csvArr?.length > 0) {
      csvArr.map((item: any) => this.res.push(item))
      this.res.sort((a: any, b: any) => {
        return parseInt(b.archive_year) - parseInt(a.archive_year)
      });
      this.gridApi.setRowData(this.res);
    }
  }

  //formating business + subrisk data table for present year data
  getBusinessSubriskDataReportFormat(found: any, currentYearData: any) {
    let reportGraphNodes: any = [];
    if (currentYearData[0][this.presentYear] && Object.keys(currentYearData[0][this.presentYear]).length > 0) {
      Object.entries(currentYearData[0][this.presentYear]).forEach((entry: any) => {
        const [id, business] = entry;

        business.forEach((data: any) => {
          var nodes = data.nodes;
          nodes = nodes.filter((obj: any) => {
            obj['t2Weight'] = data.weightage;
            return obj;
          });
          nodes.sort((a: any, b: any) => {
            return a.nodeid - b.nodeid
          });
          if (!reportGraphNodes[data.business.industry.name]) {
            reportGraphNodes[data.business.industry.name] = {};
          }
          reportGraphNodes[data.business.industry.name][data.business.name] = nodes;
        });
      });
      var csvArr: any = [];
      let reportBusinessnames = [];
      Object.entries(reportGraphNodes).forEach((entry: any) => {
        const [industryName, industries] = entry;
        Object.entries(industries).forEach((entry2: any) => {
          const [BusinessIndex, businesses] = entry2;
          reportBusinessnames.push(BusinessIndex);
          businesses.forEach((item: any) => {
            csvArr = this.reportGameBoardFormat(csvArr, item, this.presentYearString, industryName, BusinessIndex, 'subrisk');
          });
        });
      });
    }
    if (csvArr?.length > 0) {
      csvArr.map((item: any) => this.res.push(item))
      this.res.sort((a: any, b: any) => {
        return parseInt(b.archive_year) - parseInt(a.archive_year)
      });
      this.gridApi.setRowData(this.res);
    }
  }

  // calculating present year data
  reportGameBoardFormat(csvArr: any, item: any, index: any, industry: any = '', business: any = '', reportType: any = '') {
    let obj: any = {};
    obj.archive_year = index;
    obj.company_name = 'GE';

    if (industry != '') {
      obj.industry_name = industry;
    }

    if (business != '') {
      obj.business_name = business;
      obj['business_weightage'] = item.t2Weight == 'undefined' || item.t2Weight == null || item.t2Weight === '' || isNaN(item.t2Weight) ? 'N/A' : item.t2Weight + '%';
    }
    else if (industry != '') {
      obj['industry_weightage'] = (item.t1Weight == 'undefined' || item.t1Weight == null || item.t1Weight === '' || isNaN(item.t1Weight)) ? 'N/A' : item.t1Weight + '%';
    }

    obj.riskarea_name = (item.title).replace("'", "\'");
    obj.riskarea_code = item.label;

    if (industry == '') {
      let QUAD_X = this.utilService.xPixelIntoXCoodinate(item.locationX);
      let QUAD_Y = this.utilService.yPixelIntoYCoodinate(item.locationY);

      let QUAD_Z = (obj.archive_year > 2020 ? this.getResidualScore2021(QUAD_X, QUAD_Y) : this.getResidualScore2020(QUAD_X, QUAD_Y));

      obj['riskarea_mode'] = 'Auto';
      obj['override_inherent'] = QUAD_Y;
      obj['override_control'] = QUAD_X;

      obj['override_residual'] = QUAD_Z;
      obj['rating_inherent'] = this.inherentRiskMode(QUAD_Y);
      obj['rating_control'] = this.controlRiskMode(QUAD_X);
      obj['rating_residual'] = this.residualRiskMode(QUAD_Z);

      obj['auto_inherent'] = QUAD_Y;
      obj['auto_control'] = QUAD_X;
      obj['auto_residual'] = QUAD_Z;

      if (typeof item.overridenX !== 'undefined') {
        obj['riskarea_mode'] = 'Override';

        let overriden_X = this.utilService.xPixelIntoXCoodinate(item.overridenX);
        let overriden_Y = this.utilService.yPixelIntoYCoodinate(item.overridenY);

        obj['override_inherent'] = overriden_Y;
        obj['override_control'] = overriden_X;

        let residual = (obj.archive_year > 2020 ? this.getResidualScore2021(overriden_X, overriden_Y) : this.getResidualScore2020(overriden_X, overriden_Y));

        obj['override_residual'] = residual;

        obj['rating_inherent'] = this.inherentRiskMode(overriden_Y);
        obj['rating_control'] = this.controlRiskMode(overriden_X);
        obj['rating_residual'] = this.residualRiskMode(residual);
      }
    }
    else {
      let AUTO_X = this.utilService.xPixelIntoXCoodinate(item.locationX);
      let AUTO_Y = this.utilService.yPixelIntoYCoodinate(item.locationY);

      let OVERRIDEN_X = item.override ? this.utilService.xPixelIntoXCoodinate(item.override.newLocationX) : AUTO_X;
      let OVERRIDEN_Y = item.override ? this.utilService.yPixelIntoYCoodinate(item.override.newLocationY) : AUTO_Y;

      obj['riskarea_mode'] = item.override ? 'Override' : 'Auto';

      obj['override_inherent'] = OVERRIDEN_Y;
      obj['override_control'] = OVERRIDEN_X;

      let OVERRIDEN_RESIDUAL = (obj.archive_year > 2020 ? this.getResidualScore2021(OVERRIDEN_X, OVERRIDEN_Y) : this.getResidualScore2020(OVERRIDEN_X, OVERRIDEN_Y));
      var residual_AUTO = (obj.archive_year > 2020 ? this.getResidualScore2021(AUTO_X, AUTO_Y) : this.getResidualScore2020(AUTO_X, AUTO_Y));

      obj['override_residual'] = OVERRIDEN_RESIDUAL;
      obj['rating_inherent'] = this.inherentRiskMode(OVERRIDEN_Y);
      obj['rating_control'] = this.controlRiskMode(OVERRIDEN_X);
      obj['rating_residual'] = this.residualRiskMode(OVERRIDEN_RESIDUAL);

      obj['auto_inherent'] = AUTO_Y;
      obj['auto_control'] = AUTO_X;
      obj['auto_residual'] = residual_AUTO;
    }
    if (business != '') {
      obj["drivers_inherent"] = item.comments;
      obj['drivers_control'] = item.keyControls;
      obj['drivers_others'] = item.otherComments; // NEWLY ADDED IN BUSINESS REPORT

      if ((reportType == 'subrisk') && (item.subrisks.length > 0)) {
        var subrisks = item.subrisks;
        subrisks.forEach((eachSubRisk: any, eachSubRiskIndex: any) => {
          var obj1: any = new Object();
          obj1 = obj;
          obj1 = JSON.stringify(obj);
          obj1 = JSON.parse(obj1);

          obj1['subrisk_name'] = eachSubRisk.name; // STRING
          obj1['subrisk_required'] = this.subriskType(eachSubRisk.isMandatory);
          obj1['subrisk_weightage'] = eachSubRisk.weight + '%';
          obj1['subrisk_inherent_likelihood'] = eachSubRisk.inherentLikelihood; // NUMBER
          obj1['subrisk_inherent_severity'] = eachSubRisk.inherentSeverity;   // NUMBER

          obj1['subrisk_inherent_auto_score'] = Number(Number(eachSubRisk.inherentLikelihood + eachSubRisk.inherentSeverity) / 2); // number | calculated risk
          obj1['subrisk_inherent_final_score'] = eachSubRisk.inherentFinalScore; // NUMBER

          obj1['subrisk_inherent_mode'] = (obj1['subrisk_inherent_auto_score'] == obj1['subrisk_inherent_final_score'] ? 'Auto' : 'Override'); // if 'subrisk_inherent_auto_score' == Final - SR Inherent Score then AUTO else OVERRIDE;
          obj1['subrisk_inherent_rating'] = this.inherentRiskMode(eachSubRisk.inherentFinalScore); // MODE

          obj1['subrisk_control_rating'] = eachSubRisk.controlRating; // NUMBER
          obj1['subrisk_control_auto_score'] = eachSubRisk.controlRating; // NUMBER
          obj1['subrisk_control_final_score'] = eachSubRisk.controlFinalScore; // NUMBER
          obj1['subrisk_control_mode'] = (obj1['subrisk_control_auto_score'] == obj1['subrisk_control_final_score'] ? 'Auto' : 'Override'); // if Auto - SR Control Score == Final - SR Control Score then MODE = AUTO else MODE = OVERRIDE

          obj1['subrisk_control_effectiveness_rating'] = this.controlRiskMode(eachSubRisk.controlFinalScore); //MODE
          obj1['subrisk_residual_score'] = eachSubRisk.residualFinalScore; // NUMBER
          obj1['subrisk_residual_rating'] = this.residualRiskMode(eachSubRisk.residualFinalScore); // MODE
          obj1['subrisk_notes'] = (eachSubRisk.notes == null || eachSubRisk.notes == '' ? '' : eachSubRisk.notes); // STRING
          obj1['subrisk_control_list'] = (eachSubRisk.controlList == null || eachSubRisk.controlList == '' ? '' : eachSubRisk.controlList); // STRING
          obj1['updated_by'] = (eachSubRisk.subriskUser == null) ? 'N/A' : eachSubRisk.subriskUser;
          obj1['updated_at'] = this.getUpdatedAtDate(eachSubRisk.updatedAt);

          csvArr.push(obj1);
        });
        this.sortByField(csvArr, "subrisk_name");
      }
    }

    // REPORTING LOGIC RE-WRITE
    switch (this.selectedReportType) {

      case 'report-for-company':
        obj['updated_by'] = typeof (item.updatedBy) == 'undefined' ? 'N/A' : this.user(item).trim();
        obj['updated_at'] = item.updatedBy == null ? null : this.getUpdatedAtDate(item.updatedAt);

        var keys = Object.keys(this.fetchReportResponse.currentYear.overriden);

        if ((keys.length > 0)) {
          keys.forEach(key => {
            var DATA = this.fetchReportResponse.currentYear.overriden[this.presentYear];
            DATA.forEach((itemby: any) => {
              if (item.nodeid == itemby.nodeId && itemby.actionType == 'Company Overidden') {
                obj['updated_by'] = typeof (this.getUpdatedByUser(itemby.createdBy)) == 'undefined' ? 'N/A' : this.getUpdatedByUser(itemby.createdBy);
                let updated = typeof (this.getUpdatedByUser(itemby.createdBy)) == 'undefined' ? 'N/A' : this.getUpdatedByUser(itemby.createdBy);
                obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(itemby.updatedAt);
              }
            });
          });
        }
        break;

      case 'report-for-industry':
        var driversArrayDate = new Date(item.drivers.updated_at);
        var nodeArrayDate = new Date(item.updatedAt);
        if (item.override) {
          var overrideArrayDate = new Date(item.override.updatedAt);
        }
        if (this.singleBusinessIndustry && this.singleBusinessIndustry.length > 0) {
          this.singleBusinessIndustry.forEach((singleElement: any) => {
            if (singleElement == industry) {
              obj["drivers_inherent"] = item.comments;
              obj['drivers_control'] = item.keyControls;
              obj['drivers_others'] = item.otherComments;


              if (item && item.override) {
                if (nodeArrayDate.getTime() >= overrideArrayDate.getTime()) {
                  obj['updated_by'] = this.nodeUser(item);
                  let updated = this.nodeUser(item)
                  obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.updatedAt);
                }
                else {
                  obj['updated_by'] = this.user(item);
                  let updated = this.user(item)
                  obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.override.updatedAt);
                }
              }
              else {
                obj['updated_by'] = this.nodeUser(item);
                let updated = this.nodeUser(item)
                obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.updatedAt);
              }
            }
          })
        }
        if (this.multipleBusinessIndustry && this.multipleBusinessIndustry.length > 0) {
          this.multipleBusinessIndustry.forEach((multipleElement: any) => {
            if (multipleElement == industry) {
              obj["drivers_inherent"] = item.drivers.inherent_drivers;
              obj['drivers_control'] = item.drivers.control_drivers;
              obj['drivers_others'] = item.drivers.other_drivers;

              if (item && Object.keys(item.drivers).length > 0 && item.override) {
                if (driversArrayDate.getTime() >= overrideArrayDate.getTime()) {
                  obj['updated_by'] = this.driversUser(item);
                  let updated = this.driversUser(item)
                  obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.drivers.updated_at);
                }
                else {
                  obj['updated_by'] = this.user(item);
                  let updated = this.user(item)
                  obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.override.updatedAt);
                }
              }
              else if (item && Object.keys(item.drivers).length > 0 && !item.override) {
                obj['updated_by'] = this.driversUser(item);
                let updated = this.driversUser(item)
                obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.drivers.updated_at);
              }
              else if (item && Object.keys(item.drivers).length == 0 && item.override) {
                obj['updated_by'] = this.user(item);
                let updated = this.user(item)
                obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.override.updatedAt);
              }
              else {
                obj['updated_by'] = this.nodeUser(item);
                let updated = this.nodeUser(item);
                obj['updated_at'] = updated == 'N/A' ? null : this.getUpdatedAtDate(item.updatedAt);
              }
            }
          })
        }
        break;

      case 'report-for-business':
        obj['updated_by'] = typeof (item.updatedBy) == 'undefined' ? 'N/A' : this.user(item).trim();
        obj['updated_at'] = item.updatedBy == null ? null : this.getUpdatedAtDate(item.updatedAt);
        break;
    }
    // swtich ends

    this.parseDate(obj['updated_at']);

    // Not adding any child node. Also, this is to be only executed in case of COMPANY, INDUSTRY, AND BUSINESS NOT IN SUBRISK.
    if ((item.riskid) != 0 && reportType != 'subrisk') {
      csvArr.push(obj);
    }

    this.sortByField(csvArr, "riskarea_name");
    this.sortByField(csvArr, "business_name");
    this.sortByField(csvArr, "industry_name");

    return csvArr;
  }

  getUpdatedAtDate(updated_at: any) {
    if (updated_at == 'undefined' || updated_at == null)
      return null;
    else {
      return new Date(updated_at.replace("T", " ").replace(".000Z", ""));
    }
  }

  user(item: any) {
    if (typeof (item.user) != 'undefined') {
      if (item.user != null && Object.keys(item.user).length > 0)
        return item.user.username.trim() + " (" + item.user.sso.trim() + ")";
    }
    return 'N/A';
  };

  nodeUser(item: any) {
    if (typeof (item.nodeUser) != 'undefined') {
      if (item.nodeUser != null && Object.keys(item.nodeUser).length > 0)
        return `${item.nodeUser.username.trim()} (${item.nodeUser.sso.trim()})`;
    }
    return 'N/A';
  };

  driversUser(item: any) {
    if (typeof (item.drivers) != 'undefined') {
      if (item.drivers != null && Object.keys(item.drivers).length > 0)
        return `${item.drivers.username.trim()} (${item.drivers.sso.trim()})`;
    }
    return 'N/A';
  };

  getUpdatedByUser(createdBy: any) {
    var USER_FOUND = '';
    this.fetchReportResponse.currentYear.username.forEach((data: any) => {
      if (createdBy == data.id)
        USER_FOUND = data.username.trim() + " (" + data.sso.trim() + ")";
      else
        USER_FOUND = 'N/A';
    });
    return USER_FOUND;
  };

  subriskType(VALUE: any) {
    var type = '';

    if (VALUE == 0)
      type = 'Mandatory';
    else if (VALUE == 1)
      type = 'Custom';
    else
      type = '';
    return type;
  }

  inherentRiskMode(VALUE: any) {
    var MODE = 'TBD';
    VALUE = Number(VALUE);

    if (VALUE > 4 && VALUE <= 5)
      MODE = 'Very High';
    else if (VALUE > 3 && VALUE <= 4)
      MODE = 'High';
    else if (VALUE > 2 && VALUE <= 3)
      MODE = 'Moderate';
    else if (VALUE > 1 && VALUE <= 2)
      MODE = 'Low';
    else if (VALUE > 0 && VALUE <= 1)
      MODE = 'Very Low';
    else
      MODE = 'Not Applicable';
    return MODE;
  }

  controlRiskMode(VALUE: any) {
    var MODE = 'TBD';
    VALUE = Number(VALUE);

    if (VALUE > 4 && VALUE <= 5)
      MODE = 'Ineffective';
    else if (VALUE > 3 && VALUE <= 4)
      MODE = 'Marginally Effective';
    else if (VALUE > 2 && VALUE <= 3)
      MODE = 'Adequately Effective';
    else if (VALUE > 1 && VALUE <= 2)
      MODE = 'Substantively Effective';
    else if (VALUE > 0 && VALUE <= 1)
      MODE = 'Fully Effective';
    else
      MODE = 'Not Applicable';
    return MODE;
  }

  residualRiskMode(VALUE: any) {
    var MODE = 'TBD';
    VALUE = Number(VALUE);

    if (VALUE > 4 && VALUE <= 5)
      MODE = 'Critical';
    else if (VALUE > 3 && VALUE <= 4)
      MODE = 'High';
    else if (VALUE > 2 && VALUE <= 3)
      MODE = 'Moderate';
    else if (VALUE > 1 && VALUE <= 2)
      MODE = 'Low';
    else if (VALUE > 0 && VALUE <= 1)
      MODE = 'Insignificant';
    else
      MODE = 'Not Applicable';
    return MODE;
  }

  sortByField(myArrayData: any, sortField: any) {
    myArrayData.sort((a: any, b: any) => {
      if (a[sortField] < b[sortField]) { return -1; }
      if (a[sortField] > b[sortField]) { return 1; }
      return 0;
    })
  }

  parseDate(date: any) {
    let formattedDate = this.datePipe.transform(Date.parse(date), 'dd-MM-yyyy HH:mm');
    return formattedDate;
  }

  getResidualScore2021(VALUE_CONTROL: any, VALUE_INHERENT: any) {
    VALUE_CONTROL = Number(VALUE_CONTROL);
    VALUE_INHERENT = Number(VALUE_INHERENT);

    var SUM = VALUE_CONTROL + VALUE_INHERENT;
    var RESULT_AVERAGE = Number(SUM / 2);

    if (isNaN(VALUE_CONTROL) || isNaN(VALUE_INHERENT)) {
      return 0;
    } else if (RESULT_AVERAGE > VALUE_INHERENT) {
      return Number(this.utilService.roundToFixed(VALUE_INHERENT, 2));
    } else {
      return Number(this.utilService.roundToFixed(RESULT_AVERAGE, 2));
    }
  }

  getResidualScore2020(VALUE_CONTROL: any, VALUE_INHERENT: any) {

    VALUE_CONTROL = Number(VALUE_CONTROL);
    VALUE_INHERENT = Number(VALUE_INHERENT);

    var SUM = VALUE_CONTROL + VALUE_INHERENT;
    var RESULT_AVERAGE = Number(SUM / 2);

    if (isNaN(VALUE_CONTROL) || isNaN(VALUE_INHERENT))
      return 0;
    else
      return Number(this.utilService.roundToFixed(RESULT_AVERAGE, 2));
  }

  // download button click
  fireEventDownload() {
    if (this.selectedReportType == 'report-for-company') {
      let tableDataArray: any = [];
      this.gridApi.forEachNodeAfterFilter((node: any) => tableDataArray.push(node.data));
      let DownloadDataArray: any = []
      let headingArray: any = []
      this.columnDefsCompany.forEach((item: any) => {
        headingArray.push(item.headerName)
      })
      DownloadDataArray.push(headingArray)
      tableDataArray.forEach((item: any) => {
        let date: any;
        let data = item.updated_at;
        let year = Number(item.archive_year);
        if (data == null) {
          date = "N/A";
        } else if (typeof (data) === 'object') {
          date = item.updated_at
        } else if (typeof (data) === 'string') {
          let a = new Date(data).toISOString();
          date = new Date(a.replace("T", " ").replace(".000Z", ""));
        }
        let array = [year, item.company_name, item.riskarea_name, item.riskarea_code, item.riskarea_mode, item.override_inherent, item.override_control,
          item.override_residual, item.rating_inherent, item.rating_control, item.rating_residual, item.auto_inherent,
          item.auto_control, item.auto_residual, item.updated_by, date]
        DownloadDataArray.push(array)
      });
      this.downloadXLSX(DownloadDataArray, 'Company')
    }

    else if (this.selectedReportType == 'report-for-industry') {
      let tableDataArray: any = [];
      this.gridApi.forEachNodeAfterFilter((node: any) => tableDataArray.push(node.data));
      let DownloadDataArray: any = []
      let headingArray: any = []
      this.columnDefsIndustry.forEach((item: any) => {
        headingArray.push(item.headerName)
      })
      DownloadDataArray.push(headingArray)
      tableDataArray.forEach((item: any) => {
        let date: any;
        let data = item.updated_at;
        let year = Number(item.archive_year);
        if (data == null) {
          date = "N/A";
        } else if (typeof (data) === 'object') {
          date = item.updated_at
        } else if (typeof (data) === 'string') {
          let a = new Date(data).toISOString();
          date = new Date(a.replace("T", " ").replace(".000Z", ""));
        }
        let array = [year, item.company_name, item.industry_name, Number(parseFloat(item.industry_weightage)) / 100,
          item.riskarea_name, item.riskarea_code, item.riskarea_mode, item.override_inherent, item.override_control,
          item.override_residual, item.rating_inherent, item.rating_control, item.rating_residual, item.auto_inherent,
          item.auto_control, item.auto_residual, item.drivers_inherent, item.drivers_control, item.drivers_others,
          item.updated_by, date]
        DownloadDataArray.push(array)
      });
      this.downloadXLSX(DownloadDataArray, 'Industry')
    }

    else if (this.selectedReportType == 'report-for-business') {
      let tableDataArray: any = [];
      this.gridApi.forEachNodeAfterFilter((node: any) => tableDataArray.push(node.data));
      let DownloadDataArray: any = []
      let headingArray: any = []
      this.columnDefsBusiness.forEach((item: any) => {
        headingArray.push(item.headerName)
      })
      DownloadDataArray.push(headingArray)
      tableDataArray.forEach((item: any) => {
        let date: any;
        let data = item.updated_at;
        let year = Number(item.archive_year);
        if (data == null) {
          date = "N/A";
        } else if (typeof (data) === 'object') {
          date = item.updated_at
        } else if (typeof (data) === 'string') {
          let a = new Date(data).toISOString();
          date = new Date(a.replace("T", " ").replace(".000Z", ""));
        }
        let array = [year, item.company_name, item.industry_name, item.business_name, Number(parseFloat(item.business_weightage)) / 100,
          item.riskarea_name, item.riskarea_code, item.riskarea_mode, item.override_inherent, item.override_control,
          item.override_residual, item.rating_inherent, item.rating_control, item.rating_residual, item.auto_inherent,
          item.auto_control, item.auto_residual, item.drivers_inherent, item.drivers_control, item.drivers_others,
          item.updated_by, date]
        DownloadDataArray.push(array)
      });
      this.downloadXLSX(DownloadDataArray, 'Business')
    }

    else if (this.selectedReportType == 'report-for-business-subrisk') {
      let tableDataArray: any = [];
      this.gridApi.forEachNodeAfterFilter((node: any) => tableDataArray.push(node.data));
      let DownloadDataArray: any = []
      let headingArray: any = []
      this.columnDefsBusinessSubRisks.forEach((item: any) => {
        headingArray.push(item.headerName)
      })
      DownloadDataArray.push(headingArray)
      tableDataArray.forEach((item: any) => {
        let date: any;
        let data = item.updated_at;
        let year = Number(item.archive_year);
        if (data == null) {
          date = "N/A";
        } else if (typeof (data) === 'object') {
          date = item.updated_at
        } else if (typeof (data) === 'string') {
          let a = new Date(data).toISOString();
          date = new Date(a.replace("T", " ").replace(".000Z", ""));
        }
        let array = [year, item.company_name, item.industry_name, item.business_name, Number(parseFloat(item.business_weightage)) / 100,
          item.riskarea_name, item.riskarea_code, item.riskarea_mode, item.override_inherent, item.override_control,
          item.override_residual, item.rating_inherent, item.rating_control, item.rating_residual, item.auto_inherent,
          item.auto_control, item.auto_residual, item.drivers_inherent, item.drivers_control, item.drivers_others,
          item.subrisk_name, item.subrisk_required, Number(parseFloat(item.subrisk_weightage)) / 100, item.subrisk_inherent_likelihood, item.subrisk_inherent_severity,
          item.subrisk_inherent_auto_score, item.subrisk_inherent_final_score, item.subrisk_inherent_mode, item.subrisk_inherent_rating,
          item.subrisk_control_rating, item.subrisk_control_auto_score, item.subrisk_control_final_score,
          item.subrisk_control_mode, item.subrisk_control_effectiveness_rating, item.subrisk_residual_score,
          item.subrisk_residual_rating, item.subrisk_notes, item.subrisk_control_list,
          item.updated_by, date]
        DownloadDataArray.push(array)
      });
      this.downloadXLSX(DownloadDataArray, 'BusinessSubRisk')
    }
  }

  downloadXLSX(array: any, name: any) {
    array.forEach((val: any) => {
      val[val.length - 1] = isNaN(Date.parse(val[val.length - 1])) ? val[val.length - 1] : new Date(val[val.length - 1])
    })
    let ws = XLSX.utils.aoa_to_sheet(array);

    /* Percentage format for weightage*/
    var decimalFormat = `0.00\%`;
    var IntegerFormat = `0\%`;
    /* change cell format of column contain weightage in their name*/
    var col = [];
    for (let i = 0; i <= array[0].length; i++) {
      if (array[0][i] != undefined && (array[0][i].includes("Weightage") || array[0][i].includes("Weighting"))) {
        col.push(i);
      }
    }
    for (let y = 0; y <= col.length; y++) {
      var range = { s: { r: 0, c: col[y] }, e: { r: array.length, c: col[y] } };
      for (var R = range.s.r; R <= range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
          var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
          if (!cell || cell.t != 'n') continue; // only format numeric cells
          if (((cell.v) * 100) % 1 == 0) {
            cell.z = IntegerFormat
          }
          else {
            cell.z = decimalFormat;
          }
        }
      }
    }
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, `RGB_Report_` + name + `.xlsx`);
  }

}
