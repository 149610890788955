
    <ng-scrollbar style="height: 100vh;">
      <header>
        <!-- <ngx-loading-bar color="#FE5000" [includeSpinner]="false"></ngx-loading-bar> -->
        <app-navbar *ngIf="isLoaded" [hide]="hideTabs"></app-navbar>
      </header>
      <main *ngIf="isLoaded">
        <router-outlet></router-outlet>
      </main>
    </ng-scrollbar>
    <div>
      <figure *ngIf="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </figure>
    </div>
