import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariableService {

  constructor() { }

  public loginUserDetails: any = {
    sso: '',
    userId: '',
    userName: '',
    isAdmin: '',
    userData: [],
    isReader:false,
    isEditor:false,
    roleData: '',
  };
  public updateUserDetails: any = [];

  public industryDetails: any = [];

  public riskareaArray: any;
  public isIdleSession: boolean = false;

  public needReload: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  ngOnInit() {
    if ( localStorage['loginUserDetails'] ) {
      this.loginUserDetails = localStorage['loginUserDetails']
    }
  }

}
