<div class="container">
  <div class="mt-4 p-5 text-white rounded" style="background: #048f91">
    <h1 class="font-ge fs-1">Access Forbidden!</h1>
    <p class="fs-6">
      Your security token has been expired.
      <a href="/" routerLinkActive="active" class="text-decoration-none clientName"
        >Click Here</a
      >
      to reload the application.
    </p>
  </div>
</div>
