import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorized-error',
  templateUrl: './authorized-error.component.html',
  styleUrls: ['./authorized-error.component.scss']
})
export class AuthorizedErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
