<div class="container">
  <div class="mt-4 p-5 text-white rounded" style="background: #0c2340">
    <h1 class="font-ge fs-1">You are not authorized!</h1>
    <p class="fs-6">
      You do not have access. Please send an email to
      <a href="mailto:RGB_Functional_Owner@ge.com" class="text-decoration-none"
        >@CORP RGB_Functional Owner</a
      >
      with details of business, read/edit rights and justification.
    </p>
  </div>
</div>
