export const environment = {
  production: false,
  api: 'https://dn281r75o7.execute-api.us-east-1.amazonaws.com/v2',

  // Cognito URL and client id for SSO redirection
  cognitoRedirectUri: 'https://vnriskgameboard.auth.us-east-1.amazoncognito.com',
  cognitoClientId: '241t39lvvhq5pa6aurncr83f95',

  cognitoIdentityProvider: 'GESSO',

  // AWS credentials for SSO authentication
  awsRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_sNeFcCAKL',
  cognitoIdentityPoolId: 'us-east-1:ca64ef91-1fdc-4958-9926-04efc0c694ed',


  // Cognito logout URI
  cognitoLogoutUri: 'https://ssologin.ssogen2.corporate.ge.com',

  // Token credentials for APIGEE Service
  tokenUrl: 'https://fssfed.stage.ge.com/fss/as/token.oauth2',
  clientId: 'kTytfbM080PmCzVJSqAzlp8f1kprSrRFuPZgZR7j61GA2KQB',
  clientSecret: 'P3ghm0VcD1mFwvDKkx9MygQABHlvKnUe6ElNDmNbd7XBjd9ziGrPuXNmj9uizSND',

  // APIGEE service URL
  apiUrl: 'https://stage.api.ge.com/digital/ssogenericread/v2/ReadData',
};
