<div class="container">
  <div class="mt-4 p-5 text-white rounded" style="background: #0c2340">
    <h1 class="font-ge fs-1">Internal Server Error!</h1>
    <p class="fs-6">
      Please try again after connecting to VPN or contact
      <a href="mailto:riskgameboard@ge.com" class="text-decoration-none"
        >@CORP Risk Gameboard Support</a
      >
      to enable traffic for your ip
    </p>
  </div>
</div>
