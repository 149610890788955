<div fxLayout="column" fxFlexFill>
    <mat-toolbar color="primary">
        <div class="custom_header">
            <nav class="navbar navbar-expand-lg">
                <img src="./assets/images/ge-logo_original.png" height="60px" width="60px">Risk Assessment Tool
                <span class="txt hidden_mobile">powered by GE Business</span>

                <div class="user_logo" *ngIf="userData">
                    <a [routerLink]="userNavigateAdmin()" [ngClass]="isUserAdmin ? '' : 'disable'">
                        <mat-icon *ngIf="!isUserAdmin">account_circle</mat-icon>
                        <mat-icon *ngIf="isUserAdmin">manage_accounts_outlined</mat-icon>
                        <span class="hidden_mobile userName"> {{userData.username}} </span>
                    </a>
                </div>
            </nav>
        </div>
    </mat-toolbar>

    <mat-toolbar id="navbar" color="primary"
        *ngIf="!pageNotFound() && !unauthorized() && !servererror() && !forbidden()" class="mat-tooling hidden_mobile">
        <div class="navbar-default">
            <div fxLayout="row" class="pr-2" fxLayoutAlign="start center" *ngIf="!hide">
                <div class="dropdown-desktop .navbar-menu">
                    <a routerLink="/businessLevel/companyRollups" routerLinkActive="active" class="theme"
                        [ngClass]="{current: businessActive() ? 'active': ''}"> Business Level Gameboard<span
                            class="caret"></span></a>
                    <div class="dropdown-content-desktop">
                        <ul>
                            <li *ngFor="let industry of menuAndSubMenu | orderBy: 'industryName'">
                                <a [hidden]="industry.businesses.length == 0"
                                    [routerLink]="goToT1IndustryPage(industry.industryId)" style="min-width: 150px">{{
                                    industry.industryName }}
                                    <span class="caret right"></span>
                                </a>
                                <!-- data-toggle="dropdown"  -->
                                <ul class="business-menu">
                                    <li *ngFor="let business of industry.businesses | orderBy: 'businessName'">
                                        <a
                                            [routerLink]="goToT2BusinessPage(industry.industryId, business.businessId)">{{business.businessName}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dropdown-desktop">
                    <a routerLink="/riskLevel/t1RollupsNodes" routerLinkActive="active" class="theme"
                        [ngClass]="{current1: riskActive() ? 'active' : '' }">
                        Risk Level Gameboard<span class="caret"></span>
                    </a>
                    <div class="dropdown-content-desktop riskarea-menu">
                        <ul>
                            <li *ngFor="let risk of riskNodesArray | orderBy: 'label'">
                                <a routerLink="/riskLevel/t1RollupsNodes/{{risk.id}}">{{ risk.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <a routerLink="/reports" routerLinkActive="active" class="theme"
                    [ngClass]="{current2: '/reports' ? 'active' : ''}">Reports</a>
                <a class="log-right" (click)="logOut();">
                    <mat-icon class="custom-icon">login</mat-icon>Log Out
                </a>
            </div>
        </div>
    </mat-toolbar>

    <!-- responsive nav for mobile and tab -->
    <mat-toolbar color="primary" *ngIf="!pageNotFound() && !unauthorized() && !servererror() && !forbidden()"
        class="mat-tooling show_smallScreen">

        <div class="dropdown" *ngIf="!hide">
            <div class="menu_icon">
                <button type="button" class="dropbtn" mat-button>
                    <mat-icon>menu_outlined</mat-icon>
                </button>
            </div>
            <div class="dropdown-content">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <span>
                            <mat-icon mat-list-icon>grading</mat-icon>
                            <a class="nav-link" routerLink="/businessLevel/companyRollups" routerLinkActive="active">
                                Business Level Gameboard
                            </a>
                        </span>
                        <ul class="dropdown-menu">
                            <li class="nav-item dropdown nav-link"
                                *ngFor="let industry of menuAndSubMenu | orderBy: 'industryName'">
                                <a class="dropdown-item"
                                    [routerLink]="goToT1IndustryPage(industry.industryId ? industry.industryId : industry.industryId)">{{
                                    industry.industryName }}<span class="caret right"></span></a>
                                <ul class="dropdown-menu">
                                    <li *ngFor="let business of industry.businesses | orderBy: 'businessName'">
                                        <a [routerLink]="goToT2BusinessPage(industry.industryId, business.businessId)"
                                            class="dropdown-item">{{business.businessName}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <mat-divider></mat-divider>
                    <li class="nav-item dropdown">
                        <mat-icon mat-list-icon>home</mat-icon>
                        <span><a class="nav-link" routerLink="/riskLevel/t1RollupsNodes">Risk Level Gameboard</a></span>
                        <ul class="dropdown-menu">
                            <li class="nav-item dropdown nav-link" *ngFor="let risk of riskNodesArray | orderBy: 'label'">
                                <a class="dropdown-item" routerLink="/riskLevel/t1RollupsNodes/{{risk.id}}">{{ risk.name
                                    }}</a>
                            </li>
                        </ul>
                    </li>

                    <mat-divider></mat-divider>
                    <li class="nav-item">
                        <mat-icon mat-list-icon>error_outline</mat-icon>
                        <a class="nav-link" routerLink="/reports">Reports</a>
                    </li>

                    <mat-divider></mat-divider>
                    <li class="nav-item">
                        <mat-icon mat-list-icon>login</mat-icon>
                        <a class="nav-link" routerLinkActive="selected no-border" (click)="logOut();">Log Out</a>
                    </li>
                </ul>
            </div>
        </div>

    </mat-toolbar>
</div>