<h2 class="font-ge mat-dialog-style" matDialogTitle>
  Error Message
  <button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content class="md-dialog-container">
  <ng-container>
    <p class="param">
      <span style="color: red"
        >Error: You are not authorized to view this application.</span
      >
      <br />Please refresh your browser. If the problem persists, please contact
      <a href="mailto:riskgameboard@ge.com">@CORP Risk Gameboard Support</a>
    </p>
  </ng-container>
</mat-dialog-content>
