import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  mergeNodeArrays(nodeListArray: any[], weightage: any, company: any) {

    if (nodeListArray.length === 0) return [];

    // Returns a 'filtered' copy of the provided node.
    // I.e. we filter out the data, which won't be included
    // into merged nodes, as we don't know a reasonable way to merge them.

    var filter = (node: any) => {
      var res = {} as any;
      for (var key in node) {
        res[key] = node[key];
      }
      if (typeof (weightage) != "undefined") {
        res.locationX = node.weight * this.roundToFixed(node.locationX * (weightage[node.graphId] / 100), 2);
        res.locationY = node.weight * this.roundToFixed(node.locationY * (weightage[node.graphId] / 100), 2);
        res.weight = node.weight * (weightage[node.graphId] / 100);
      }
      else {
        res.locationX = this.roundToFixed(node.weight * node.locationX, 2);
        res.locationY = this.roundToFixed(node.weight * node.locationY, 2);
      }
      return res;
    };


    var map = nodeListArray[0].reduce((map: any, node: any) => {
      if (typeof (node.histories) != 'undefined' && node.histories.constructor === Array && node.histories.length > 0) {
        node.histories = node.histories.filter((obj: any) => {
          obj['t2name'] = node.t2name;
          return obj;
        });
      }
      var histories = node.histories;

      if (node.archiveYear) {
        histories = JSON.parse(node.histories);
        if (typeof (histories) != 'undefined' && histories.constructor === Array && histories.length > 0) {
          histories = histories.filter((obj: any) => {
            obj['t2name'] = node.t2name;
            return obj;
          });
        }
      }
      map[node.label] = {
        count: 1,
        node: filter(node),
        histories: histories
      };
      return map;
    }, {});

    var findLatestUpdatedDateAndUpdatedBy = (record: any) => {
      var compareDate = new Date(record.updatedAt);
      for (var i = 0; i < nodeListArray.length; i++) {
        nodeListArray[i].forEach((arrayItem: any) => {
          if (arrayItem.label == record.label) {
            var d = new Date(arrayItem.updatedAt)
            if (d.getTime() > compareDate.getTime()) {
              record.updated_at = arrayItem.updated_at;
              record.updatedAt = arrayItem.updatedAt;
              record.updatedBy = arrayItem.updatedBy;
              record.user = arrayItem.user;
              record.nodeUser = arrayItem.nodeUser;
            }
          }
        });
      }
      return record;
    }
    for (var i = 1; i < nodeListArray.length; ++i) {
      nodeListArray[i].forEach((node: any) => {
        if (!map[node.label]) {
          map[node.label] = {
            count: 1,
            node: filter(node),
            histories: []
          };
        }
        else {
          var m = map[node.label];
          //var m = map[node.name]
          m.count += 1;
          if ('histories' in node && node.histories.length > 0) {
            if (typeof (node.histories) != 'undefined' && node.histories.constructor === Array && node.histories.length > 0) {
              node.histories = node.histories.filter((obj: any) => {
                obj['t2name'] = node.t2name;
                return obj;
              });
            }
            var histories = node.histories
            if (node.archiveYear) {
              histories = JSON.parse(node.histories);
              if (typeof (histories) != 'undefined' && histories.constructor === Array && histories.length > 0) {
                histories = histories.filter((obj: any) => {
                  obj['t2name'] = node.t2name;
                  return obj;
                });
              }
            }
            m.histories = m.histories.concat(histories);
          }

          if (typeof (weightage) != "undefined" && typeof (company) == "undefined") {
            var WEIGHT_PERCENT = this.roundToFixed(weightage[node.graphId] / 100, 4);

            m.node.locationX += node.weight * this.roundToFixed(node.locationX * WEIGHT_PERCENT, 2);
            m.node.locationY += node.weight * this.roundToFixed(node.locationY * WEIGHT_PERCENT, 2);
            m.node.weight += node.weight * WEIGHT_PERCENT;
            m.node.updated_at = findLatestUpdatedDateAndUpdatedBy(node).updated_at;
            m.node.updatedAt = findLatestUpdatedDateAndUpdatedBy(node).updatedAt;
            m.node.updatedBy = findLatestUpdatedDateAndUpdatedBy(node).updatedBy;
            m.node.user = findLatestUpdatedDateAndUpdatedBy(node).user;
            m.node.nodeUser = findLatestUpdatedDateAndUpdatedBy(node).nodeUser;

            // if(node.label == 'AML') {
            //   console.log( node.id , node.weight );
            //   var Y = roundToFixed( (node.locationY * 5) / 1000 , 2);
            //   var X = roundToFixed( (node.locationX * 5) / 2000 , 2);
            //   var X_into = X * WEIGHT_PERCENT;
            //   var Y_into = Y * WEIGHT_PERCENT;
            //   console.log(WEIGHT_PERCENT , "Y: ",  Y , "X: " , X );
            //   console.log("graphId:" , node.graphId, 'final Y' , roundToFixed( Y_into , 2) , 'final X' , roundToFixed( X_into , 2));
            //   // console.log("SUM Y: ", (m.node.locationY.toFixed(1) * 5) / 1000 , "SUM X: " , (m.node.locationX.toFixed(1) * 5) / 2000 , );
            // }
          }
          else {
            m.node.locationX += node.weight * node.locationX;
            m.node.locationY += node.weight * node.locationY;
            m.node.weight += node.weight;
          }
        }
      });
    }
    var res = [];
    for (var key in map) {
      var m = map[key];

      if (m.node.weight > 0) {
        m.node.locationX /= m.node.weight;
        m.node.locationY /= m.node.weight;
      }
      else {
        m.node.locationX = 0;
        m.node.locationY = 0;
      }

      if (typeof (weightage) != "undefined" && typeof (company) == "undefined") {
        m.node.weight = Math.round(m.node.weight);
      }
      else {
        m.node.weight = Math.round(m.node.weight / m.count);
      }

      m.node.histories = m.histories;

      res.push(m.node);
    }
    return res;
  }

  mergeNodeArraysCompany(nodeListArray: any, weightage: any, industryOverrideData: any) {
    if (nodeListArray.length === 0) return [];

    if (typeof (industryOverrideData) == 'undefined')
      industryOverrideData = [];

    var filter = (node: any) => {
      var res = {} as any;
      for (var key in node) res[key] = node[key];

      res.locationX = 0; //node.locationX * (parseFloat(weightage[0]) / 100);
      res.locationY = 0; //node.locationY * (parseFloat(weightage[0]) / 100);
      return res;
    };

    var findLatest = (record: any) => {
      var compareDate = new Date(record.updatedAt);
      for (var i = 0; i < nodeListArray.length; i++) {
        nodeListArray[i].forEach((arrayItem: any) => {
          if (arrayItem.label == record.label) {
            var d = new Date(arrayItem.updatedAt);
            if (d.getTime() > compareDate.getTime()) {
              record.updated_at = arrayItem.updated_at;
              record.updatedAt = arrayItem.updatedAt;
              record.updatedBy = arrayItem.updatedBy;
              record.user = arrayItem.user;
              record.nodeUser = arrayItem.nodeUser;
            }
          }
        });
      }
      return record;
    }

    var map = nodeListArray[0].reduce((map: any, node: any) => {
      map[node.label] = {
        count: 1,
        node: filter(node)
      };
      return map;
    }, {});

    var i = 0;
    while (i < nodeListArray.length) {
      nodeListArray[i].forEach((node: any) => {
        if (!map[node.label]) {
          map[node.label] = {
            count: 1,
            node: filter(node),
          };
        }
        else {
          var m = map[node.label];
          m.count += 1;

          // 1. GETTING THE DATA FROM INDUSTRY_OVERRIDE ARRAY.
          var over = industryOverrideData.find((data: any) => {
            return ((data.industryId === node.industryId) && (data.nodeId === node.nodeid));
          });

          // 2. CHECKING IF FOUND OR NOT. IF FOUND USE IT ELSE USE AUTO VALUE ONLY.
          node.locationX = (typeof (over) !== 'undefined') ? over.newLocationX : node.locationX;
          node.locationY = (typeof (over) !== 'undefined') ? over.newLocationY : node.locationY;

          // 3. PASSING THE VALUE
          var WEIGHT_PERCENT = this.roundToFixed(weightage[i] / 100, 4);
          m.node.locationX += this.roundToFixed(node.locationX, 2) * WEIGHT_PERCENT;
          m.node.locationY += this.roundToFixed(node.locationY, 2) * WEIGHT_PERCENT;
          m.node.weight += node.weight;
          m.node.updated_at = findLatest(node).updated_at;
          m.node.updatedAt = findLatest(node).updatedAt;
          m.node.updatedBy = findLatest(node).updatedBy;
          m.node.user = findLatest(node).user;
          m.node.nodeUser = findLatest(node).nodeUser;


          // if(node.label == 'COI') {
          //   var Y = (node.locationY.toFixed(1) * 5) / 1000;
          //   var X = (node.locationX.toFixed(1) * 5) / 2000;
          //   var X_into = X * WEIGHT_PERCENT;
          //   var Y_into = Y * WEIGHT_PERCENT;
          //   console.log(WEIGHT_PERCENT * 100 +'%' , "Y: ",  Y , "X: " , X );
          //   console.log("graphId:" , node.graphId, 'final Y' , Y_into.toFixed(2) , 'final X' , X_into.toFixed(2));
          // }

        }
      });
      i++;
    }

    var res = [];
    for (var key in map) {
      var m = map[key];
      m.node.weight = Math.round(m.node.weight / m.count);
      res.push(m.node);
    }
    return res;
  }

  archiveMergeNodeArrays(nodeArr: any, weightage: any, year: any) {
    if (parseInt(year) < 2018) {
      return this.mergeNodeArrays(nodeArr, {}, undefined);
    } else {
      return this.mergeNodeArrays(nodeArr, weightage, undefined);
    }
  }

  xPixelIntoXCoodinate(value: any) {
    return Number(this.roundToFixed((value * 5) / 2000, 2));
  }

  yPixelIntoYCoodinate(value: any) {
    return Number(this.roundToFixed((value * 5) / 1000, 2));
  }

  roundToFixed(val: any, digits = 0) {
    if (typeof val === 'number' && typeof digits === 'number'
      && Number.isInteger(digits) &&
      (digits === 0 || Math.sign(digits) === 1)) {
      const valF = this.numToFloat(val);
      const valFNew = this.numToFloat(
        Math.round(+(valF.mant + 'e' + (valF.exp + digits)))
      );
      return +(valFNew.mant + 'e' + (valFNew.exp - digits));
    }
    return NaN;
  }

  numToFloat(val: any) {
    const [mant, exp] = Number(val)
      .toExponential()
      .split('e')
      .map(str => +str);
    return {
      mant,
      exp
    };
  }

  xOnGraph(value: any) {
    return Number(((value * 2000) / 5).toFixed(3));
  }

  yOnGraph(value: any) {
    return Number(((value * 1000) / 5).toFixed(3));
  }
  isLocalEnv() {
    return window.location.hostname.toLowerCase().includes('localhost');
  }
}
