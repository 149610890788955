import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, filter, first } from 'rxjs';
import * as AWS from 'aws-sdk';
import * as aws4 from 'aws4';
import * as url from 'url';
import * as qs from 'querystring';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private refreshing = false;
  paused$ = new BehaviorSubject(false);
  constructor() { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isApiGatewayUrl(request.url)) {
      return next.handle(request); // Only handle API Gateway URLs
    }

    if (
      AWS.config.credentials &&
      this.isExpired((AWS.config.credentials as any).expireTime) &&
      !this.refreshing
    ) {
      // Refresh the request with new AWS credentials if required
      this.refreshing = true;
      this.paused$.next(true);

      (AWS.config.credentials as AWS.Credentials).get((error) => {
        if (error) {
          // new paused$ subject to reset the flag
          this.paused$ = new BehaviorSubject<boolean>(false);
          this.refreshing = false;

          console.error('Error while refreshing aws credentials.\n', error);
          this.redirectToLoginPage();
        } else {
          localStorage['expireTime'] = (
            AWS.config.credentials as AWS.Credentials
          ).expireTime;

          this.refreshing = false;
          // Resume the paused requests
          this.paused$.next(false);
        }
      });
    }

    // Requests are paused when credentials are being generated or refreshed
    return this.paused$.pipe(
      filter((isPaused) => !isPaused),
      first(),
      switchMap(() => this.invoke(request, next))
    );
  }

  private invoke(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let finalUrl = this.isApiGatewayUrl(request.url);

    if (AWS.config.credentials) {
      const parsedUrl = url.parse(finalUrl);

      const opts: any = {
        region: 'us-east-1',
        service: 'execute-api',
        method: request.method,
        host: parsedUrl.host,
        path: parsedUrl.pathname,
        query: qs.parse(parsedUrl.query ?? ''),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      if (request.body) {
        opts.body = JSON.stringify(request.body);
      }

      const apigClient = {
        accessKeyId: AWS.config.credentials?.accessKeyId,
        secretAccessKey: AWS.config.credentials?.secretAccessKey,
        sessionToken: AWS.config.credentials?.sessionToken,
      };

      aws4.sign(opts, apigClient);

      let httpHeaders = new HttpHeaders(opts.headers);
      httpHeaders = httpHeaders.delete('host');

      request = request.clone({
        url: finalUrl,
        headers: httpHeaders,
      });
    }

    return next.handle(request);
  }

  private isApiGatewayUrl(requestUrl: string) {
    return `${environment.api}` + requestUrl;
  }

  // Checking whether my AWS credentials are expired or about to expire
  private isExpired(date: Date, offset: number = 0) {
    let awsDate: any = new Date(date);
    let currentDate: any = new Date(Date.now() + offset);
    let diffMinutes = (awsDate.getTime() - currentDate.getTime()) / 1000;
    diffMinutes /= 60;
    return diffMinutes <= 0;
  }

  private redirectToLoginPage() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href =
      `${environment.cognitoRedirectUri}/oauth2/authorize?identity_provider=${environment.cognitoIdentityProvider}&redirect_uri=` +
      location.origin +
      '&state=' +
      window.location.pathname +
      `&response_type=token&client_id=${environment.cognitoClientId}&scope=aws.cognito.signin.user.admin+openid+profile`;
  }
}
