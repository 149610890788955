import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `<button class="mat-icon-button" style="height:23px;color:{{color}}" (click)="onClick($event);">
    <mat-icon class="mat-icon material-icons mat-icon-no-color">{{icon}}</mat-icon>
  </button>`,
})

export class ButtonRendererComponent implements ICellRendererAngularComp {

  // <button type="button" (click)="onClick($event)"></button>
  params:any;
  label: string;
  icon:string;
  color:string;

  agInit(params:any): void {
    this.params = params;
    this.label = this.params.label || null;
    this.icon = this.params.icon || null;
    if(this.icon == 'delete') {
      this.color = '#d9534f'
    } else if(this.icon == 'edit') {
      this.color = '#08a5e1'
    } else if(this.icon == 'visibility') {
      this.color = 'green'
    }    
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event:any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
      }
      this.params.onClick(this.params);
    }
  }
}