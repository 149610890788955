import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  apigClientFactory: any;
  constructor(private httpClient: HttpClient) { }

  login(access_token: any): Observable<any[]> {
    let url: string = `${environment.cognitoRedirectUri}/oauth2/userInfo`;
    var options = {
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
    }
    return this.httpClient.get<any[]>(url, options);
  }
}