import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Reusable chart Component
import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';

// Components
import { NavbarComponent } from './navbar/navbar.component';
import { ReportingComponent } from './reporting/reporting.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';

// Business Level Gameboard
import { CompanyRollupsComponent } from './business-level/company-rollups/company-rollups.component';
import { T1RollupsComponent } from './business-level/t1-rollups/t1-rollups.component';
import { T2RollupsComponent } from './business-level/t2-rollups/t2-rollups.component';
import { T1RollupsIndustryComponent } from './business-level/t1-rollups-industry/t1-rollups-industry.component';
import { T2RollupsBusinessComponent } from './business-level/t2-rollups-business/t2-rollups-business.component';
import { RiskFormComponent } from './business-level/risk-form/risk-form.component';

import { AmChartsModule } from '@amcharts/amcharts3-angular';

//  Directive
import { TwoDigitInputDirective } from './directives/two-digit-input.directive';

// Service Interceptor
import { ErrorCatchingInterceptor } from './interceptor/error-catching-interceptor.interceptor';
import { HttpInterceptorService } from './interceptor/http-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ViewGraphComponent } from './modal-popup/view-graph/view-graph.component';
import { T1RollupsNodesComponent } from './risk-level/t1-rollups-nodes/t1-rollups-nodes.component';
import { T1RollupsNodesIndustryComponent } from './risk-level/t1-rollups-nodes-industry/t1-rollups-nodes-industry.component';
import { T2RollupsNodesComponent } from './risk-level/t2-rollups-nodes/t2-rollups-nodes.component';
import { T2RollupsNodesIndustryComponent } from './risk-level/t2-rollups-nodes-industry/t2-rollups-nodes-industry.component';
import { SessionOutComponent } from './modal-popup/session-out/session-out.component';
import { IdleTimeComponent } from './modal-popup/idle-time/idle-time.component';
import { AuthorizedErrorComponent } from './modal-popup/authorized-error/authorized-error.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup



@NgModule({
  declarations: [
    AppComponent,
    BubbleChartComponent,
    T1RollupsComponent,
    PageNotFoundComponent,
    CompanyRollupsComponent,
    T1RollupsIndustryComponent,
    DashboardComponent,
    T2RollupsComponent,
    T2RollupsBusinessComponent,

    NavbarComponent,
    ReportingComponent,
    RiskFormComponent,
    TwoDigitInputDirective,
    ViewGraphComponent,
    T1RollupsNodesComponent,
    T1RollupsNodesIndustryComponent,
    T2RollupsNodesComponent,
    T2RollupsNodesIndustryComponent,
    SessionOutComponent,
    IdleTimeComponent,
    AuthorizedErrorComponent,
    ForbiddenComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AmChartsModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
