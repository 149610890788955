<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise" [fullScreen]="true">
</ngx-spinner>

<div class="crumbs tablet-hide">
    <ol class="breadcrumb">
        <li class="active">Report</li>
    </ol>
</div>

<div class="container">
    <mat-card class="selectionCard">
        <div class="row">
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Report Type</mat-label>
                    <mat-select [(value)]="selectedReportType">
                        <mat-option [ngClass]="{'selected-option': selectedReportType === 'report-for-company'}" value="report-for-company">Company</mat-option>
                        <mat-option [ngClass]="{'selected-option': selectedReportType === 'report-for-industry'}" value="report-for-industry">Industry (T1)</mat-option>
                        <mat-option [ngClass]="{'selected-option': selectedReportType === 'report-for-business'}" value="report-for-business">Business (T2)</mat-option>
                        <mat-option [ngClass]="{'selected-option': selectedReportType === 'report-for-business-subrisk'}" value="report-for-business-subrisk">Business (T2) + Sub-Risks</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Years</mat-label>
                    <mat-select multiple [(value)]="selectedYears">
                        <mat-option [value]="presentYearString">{{presentYearString}}</mat-option>
                        <mat-option *ngFor="let year of yearData" [value]="year">{{year}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3"
                *ngIf="selectedReportType === 'report-for-industry' || selectedReportType === 'report-for-business' || selectedReportType === 'report-for-business-subrisk'">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label *ngIf="industryData.length > 0">Select Industry (T1)</mat-label>
                    <mat-label *ngIf="industryData.length == 0">
                        <div class="select-placeholder-container">
                            <span>Loading...</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                    </mat-label>
                    <mat-select multiple [(value)]="selectedIndustryId" [disabled]="industryData.length == 0">
                        <mat-option [value]="0" (click)="fetchGraphByIndustryId(0)">All Industries</mat-option>
                        <mat-option *ngFor="let industry of industryData | orderBy: 'industryName'" [value]="industry.industryId"
                            (click)="fetchGraphByIndustryId(industry.industryId)">{{industry.industryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3"
                *ngIf="selectedReportType === 'report-for-business' || selectedReportType === 'report-for-business-subrisk'">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label *ngIf="!fetchingData">Select Business (T2)</mat-label>
                    <mat-label *ngIf="fetchingData">
                        <div class="select-placeholder-container">
                            <span>Loading...</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                        </div>
                    </mat-label>
                    <mat-select multiple [disabled]="fetchingData" [(value)]="selectedBusinessId">
                        <mat-option *ngIf="businessDropdownData.length > 0" [value]="0"
                            (click)="formatBusinessIdArray(0)">All Business</mat-option>
                        <mat-option *ngFor="let business of businessDropdownData | orderBy: 'businessName'"
                            [value]="business.businessId" (click)="formatBusinessIdArray(business.businessId)">
                            {{business.businessName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <button mat-raised-button class="fetch-btn"
                    (click)="getCompanyData(selectedReportType , selectedYears);">Fetch Report</button>
            </div>
        </div>
    </mat-card>

    <div class="row tableCard" *ngIf="showTable">
        <div class="col-md-12">
            <mat-card>
                <div class="d-flex bd-highlight mb-2">
                    <div class="me-auto pt-2">
                        Page Size:
                        <select (change)="onPageSizeChanged($event)" [value]="paginationPageSize">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100" selected>100</option>
                            <option value="200">200</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                    <div class="align-items-end">
                        <button mat-button class="downloadButton" *ngIf="res.length > 0" (click)="fireEventDownload();">
                            <mat-icon>download</mat-icon>Download as Excel
                        </button>
                    </div>
                </div>

                <ag-grid-angular #agGrid id="agGrid" style="width: 100%; height: 450px;" class="ag-theme-alpine"
                    [columnDefs]="columnDef" [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [rowData]="res"
                    [paginationPageSize]="paginationPageSize" [suppressScrollOnNewData]="true"
                    [enableCellTextSelection]="true" [paginationAutoPageSize]="false" (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </mat-card>
        </div>
    </div>
    <div *ngIf="errorDiv" class="errorDiv">
        <label style="text-transform: uppercase;font-weight: bold;">
            <i class="fa-solid fa-circle-xmark" style="font-size: 14px;font-weight: 200;"></i>
            Error</label>
        <p>{{errorMsg}}</p>        
    </div>
</div>