import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizedErrorComponent } from '../modal-popup/authorized-error/authorized-error.component';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService, private router: Router, private dialog: MatDialog,) { }

  public dialogRef: any;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request)
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("/unauthorized");
                break;
              case 500:     //Internal server
                this.router.navigateByUrl("/servererror");
                break;
              case 404:     //notFound
                this.router.navigateByUrl("/pageNotFound");
                break;
              case 403:     //Forbidden
                this.router.navigateByUrl("/forbidden");
                break;
              case 0:     //notFound
                this.dialogRef = this.dialog.open(AuthorizedErrorComponent,
                  {
                    disableClose: true,
                    autoFocus: true,
                  });
                break;
            }
          }
          //this.toastr.error(errorMsg);
          console.log(errorMsg);
          return throwError(errorMsg);
        })
      )
  }

}
