import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public opts: any = [];

  constructor(private httpClient: HttpClient, private utilService: UtilityService) { }

  getUser(SSO: any): Observable<any[]> {
    let URL = '/users/' + SSO;
    return this.httpClient.get<any>(URL);
  }


  getBusinessDetailsByID(businessID: any): Observable<any[]> {
    let URL = '/businesses/' + businessID;
    return this.httpClient.get<any>(URL);
  }

  getBusinessGraphData(businessID: number): Observable<any[]> {
    let URL = '/graphs/business/' + businessID;
    return this.httpClient.get<any>(URL);
  }

  getGraphsIndustrywise() {
    let URL = '/graphs/industrywise';
    return this.httpClient.get<any>(URL);
  }

  riskareasGet(): Observable<any[]> {
    let URL = '/riskareas';
    return this.httpClient.get<any>(URL);
  }

  companyMoveGet(): Observable<any[]> {
    let URL = '/company/move';
    return this.httpClient.get<any>(URL);
  }

  companyMoveDelete(): Observable<any[]> {
    let URL = '/company/move/';
    return this.httpClient.delete<any[]>(URL);
  }

  nodesIdCompanyMovePut(id: any, body: any): Observable<any[]> {
    let URL = '/nodes/' + id + '/company-move';
    return this.httpClient.put<any>(URL, body);
  }

  getAllIndustries() {
    let URL = '/industries';
    return this.httpClient.get<any>(URL);
  }

  getIndustryDetailsByID(INDUSTRY_ID: any) {
    let URL = '/industries/' + INDUSTRY_ID;
    return this.httpClient.get<any>(URL);
  }

  getGraphByIndustryId(INDUSTRY_ID: any): Observable<any[]> {
    let URL = '/graphs/industry/' + INDUSTRY_ID;
    return this.httpClient.get<any[]>(URL);
  }

  companyMoveDeleteSingleNode(id: any): Observable<any[]> {
    let URL = '/company/move/' + id;
    return this.httpClient.delete<any[]>(URL);
  }

  getUsersList(): Observable<any[]> {
    let URL = '/users';
    return this.httpClient.get<any>(URL);
  }

  postUserLsit(body: any) {
    let URL = '/users';
    return this.httpClient.post<any>(URL, body);
  }

  reportsTypePost(type: string, body: any) {
    let URL = '/reports/' + type;
    return this.httpClient.post<any>(URL, body);
  }

  industriesGetRequest() {
    let URL = '/industries';
    return this.httpClient.get<any>(URL);
  }

  // Risk Form api calls ------------------------------------------------------------------------------------

  //Get Excel Tool Data by Node ID
  subrisksGet(id: any) {
    let URL = '/subrisks/' + id;
    return this.httpClient.get<any>(URL);
  }

  nodesIdMovePut(id: any, body: any): Observable<any[]> {
    let URL = '/nodes/' + id + '/move';
    return this.httpClient.put<any>(URL, body)
  }

  subrisksOptions(id: any, body: any): Observable<any[]> {
    let URL = '/subrisks';
    return this.httpClient.put<any>(URL, body)
  }

  nodesIdPut(id: any, body: any): Observable<any[]> {
    let URL = '/nodes/' + id;
    return this.httpClient.put<any>(URL, body)
  }

  subrisksDelete(subriskID: any) {
    let URL = '/subrisks/' + subriskID;
    return this.httpClient.delete<any[]>(URL);
  }

  //admin module (only) api calls ---------------------------------------------------------------------------

  userRoleDelete(roleId: any) {
    let URL = '/users/' + roleId + '/roles';
    return this.httpClient.delete<any[]>(URL);
  }

  // user registering in role section
  usersSsoPut(body: any, sso: any) {
    let URL = '/users/' + sso;
    return this.httpClient.put<any[]>(URL, body);
  }

  //users roles updating
  usersSsoRolesPutRequest(body: any, sso: any) {
    let URL = '/users/' + sso + '/roles';
    let HTTPOptions: Object = {
      responseType: 'text'
    }
    if (this.utilService.isLocalEnv()) {
      return this.httpClient.put<any[]>(URL, body, HTTPOptions);
    } else {
      return this.httpClient.put<any[]>(URL, body);
    }

  }

  userDelete(sso: any) {
    let URL = '/users/' + sso;
    return this.httpClient.delete<any[]>(URL);
  }

  getAllBusinessByIndustry(id: any) {
    let URL = '/industries/' + id + '/businesses';
    return this.httpClient.get<any[]>(URL);
  }


  // archive api calls

  getAllNodes() {
    let URL = '/nodes';
    return this.httpClient.get<any[]>(URL);
  }

  archiveYearGet(year: any) {
    let URL = '/archive/' + year;
    return this.httpClient.get<any[]>(URL);
  }

  archivePutRequest(body: any) {
    let URL = '/archive';
    return this.httpClient.put<any[]>(URL, body);
  }

  archiveSubriskPutRequest(body: any) {
    let URL = '/archive/subrisk';
    return this.httpClient.put<any[]>(URL, body);
  }


  //T1 update weightage call
  industriesUpdateweightPut(body: any) {
    let URL = '/industries/updateweight';
    return this.httpClient.put<any[]>(URL, body);
  }

  // T1 Edit/View Drivers Get call
  industryDriversGet(id: any) {
    let URL = '/drivers/industry/' + id;
    return this.httpClient.get<any[]>(URL);
  }

  // T1 Edit/View Drivers Post call
  industryDriversPost(body: any) {
    let URL = '/drivers/industry';
    return this.httpClient.post<any[]>(URL, body);
  }

  // T1 Edit/View Drivers Put call
  industryDriversUpdate(body: any) {
    let URL = '/drivers/industry';
    return this.httpClient.put<any[]>(URL, body);
  }

  // t2 add new graph
  addNewGrpah(body: any) {
    let URL = '/graphs';
    return this.httpClient.post<any>(URL, body)
  }
  //get graphs exist
  getAllGraphExist() {
    let URL = '/graphs';
    return this.httpClient.get<any>(URL)
  }
  // t2 update weightage
  businessUpdateWeightPut(body: any) {
    let URL = '/graphs/updateweight';
    return this.httpClient.put<any>(URL, body)
  }
  //t2 graph delete
  graphsIdDeleteRequest(id: number, body: any) {
    let URL = '/graphs/' + id;
    return this.httpClient.put<any>(URL, body)
  }

  //T1 Industry: Get all industry override
  getIndustryOverride(id: any) {
    let URL = '/override/industry/' + id;
    return this.httpClient.get<any>(URL);
  }

  //Save Override Position for Industry
  saveIndustryIDOverridePosition(body: any) {
    let URL = '/override/industry';
    return this.httpClient.put<any>(URL, body);
  }

  // DELETE ALL NODE i.e. RESET TO AUTO ENTIRE INDUSTRY
  resetToAutoModeForIndustry(id: any) {
    let URL = '/override/industry/all/' + id;
    return this.httpClient.delete<any>(URL);
  }

  // DELETE SINGLE NODE i.e. RESET TO AUTO SINGLE NODE
  resetTosingleNodeForIndustry(id: any) {
    let URL = '/override/industry/' + id;
    return this.httpClient.delete<any>(URL);
  }

  //View Archive Graph 
  getArchiveBusinessData(id: any) {
    let URL = '/archive/business/' + id;
    return this.httpClient.get<any>(URL);
  }

  getArchiveIndustryData(id: any) {
    let URL = '/archive/industry/' + id;
    return this.httpClient.get<any>(URL);
  }

  getArchiveCompanyData() {
    let URL = '/archive';
    return this.httpClient.get<any>(URL);
  }


  //////////////////////////////////////////////////////////
  //risk-level apis
  graphsNodeIndustryidNidGet(industryId: any, nodeId: any) {
    let URL = '/graphs/node/' + industryId + '/' + nodeId;
    return this.httpClient.get<any>(URL);
  }
  nodeidIdGetRequest(nodeId: any) {
    let URL = '/nodeid/' + nodeId
    return this.httpClient.get<any>(URL);
  }

  nodesIdPutRequest(nodeId: any, body: any) {
    let URL = '/nodes/' + nodeId
    return this.httpClient.put<any>(URL, body);
  }
  //////////////////////////report
  archiveYearsGetRequest() {
    let URL = '/archive/years'
    return this.httpClient.get<any>(URL);
  }

  // API call for token generate for our new APIGEE service
  apigeetokenGenerate(body: any) {
    let URL = '/apigeetoken';
    return this.httpClient.post<any>(URL, body);
  }

}