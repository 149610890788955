import { Injectable } from '@angular/core';
import { GlobalVariableService } from '../common-services/global-variable.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn:boolean = false;
  isAdmin = null;
  constructor(private globalService: GlobalVariableService) { }
  
  isAuthenticated() {
    if (localStorage['isAdmin'] == "true") {
      this.isLoggedIn = true
    } else {
      this.isLoggedIn = false
    } 
    return this.isLoggedIn;
  }
}
