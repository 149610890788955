import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Idle } from '@ng-idle/core';
import { GlobalVariableService } from 'src/app/common-services/global-variable.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-session-out',
  templateUrl: './session-out.component.html',
  styleUrls: ['./session-out.component.scss']
})
export class SessionOutComponent implements OnInit {

  public sessionStop: boolean = false;
 
  constructor(public dialogRef: MatDialogRef<SessionOutComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private idle: Idle,
  private globalService: GlobalVariableService
  ) { }

  ngOnInit(): void {
    console.log("session popup")
    this.idle.stop();
    this.globalService.isIdleSession = true;
  }

  logout() {
    this.dialogRef.close();
    sessionStorage.clear();
    localStorage['tokenJson'] = null;
    localStorage['user_sso'] = null;
    localStorage['expireTime'] = null;
    localStorage.clear();
    location.href = `${environment.cognitoRedirectUri}/logout?client_id=${environment.cognitoClientId}&logout_uri=${environment.cognitoLogoutUri}/logoff/logoff.jsp?referrer=` + window.location.origin + "/"
  }

}
