import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../common-services/data.service';
import { GlobalVariableService } from '../common-services/global-variable.service';
import { AuthorizedErrorComponent } from '../modal-popup/authorized-error/authorized-error.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private globalService: GlobalVariableService, private dataService: DataService, private router: Router, private activatedRoute: ActivatedRoute, private toaster: ToastrService,
    private dialog: MatDialog) {
    this.globalService.needReload.subscribe(value => {
      if (value && value.length != 0) {
        this.menuAndSubMenu = value;
        this.globalService.industryDetails = value;
      }
    });
  }

  public SS0: any;
  public userData: any;
  public isUserAdmin: any;
  public menuAndSubMenu: any;
  public riskNodesArray: any;
  public businessId: any;
  public industryId: any;
  public access: any;
  public location: Location;
  public errorDialog: any;
  @Input() hide: boolean;

  ngOnInit(): void {
    this.SS0 = this.globalService.loginUserDetails.sso
    this.userData = this.globalService.loginUserDetails.userData[0]
    this.isUserAdmin = this.globalService.loginUserDetails.isAdmin
    this.menuAndSubMenu = this.userData.industryDetails
    this.userAccess();
    this.getRiskAreas();
    let value = [];
    value = this.router.url.split('/');
    this.industryId = (value[3] != undefined && value[2] == "t1Rollups" || "t2Rollups" || "t2RollupsNodes") ? value[3] : null;
    this.businessId = (value[4] != undefined && value[2] == "t2Rollups") ? value[4] : null;
    if (this.industryId && this.businessId) {
      if (!this.requireT2UserOrAbove(this.industryId, this.businessId)) {
        this.router.navigate(['businessLevel/companyRollups']);
        this.toaster.error("User is not authorized");
        this.errorDialog = this.dialog.open(AuthorizedErrorComponent,
          {
            panelClass: 'custom-dialog',
            disableClose: true,
            autoFocus: true,
          });
      }
    } else if (this.industryId && (this.businessId == null || this.businessId == undefined)) {
      if (!this.requireT1UserOrAbove(this.industryId)) {
        this.router.navigate(['businessLevel/companyRollups']);
        this.errorDialog = this.dialog.open(AuthorizedErrorComponent,
          {
            panelClass: 'custom-dialog',
            disableClose: true,
            autoFocus: true,
          });
      }
    }
  }

  pageNotFound(): boolean {
    return window.location.href.includes("pageNotFound");
  }
  unauthorized(): boolean {
    return window.location.href.includes("unauthorized");
  }

  servererror(): boolean {
    return window.location.href.includes("servererror");
  }
  forbidden(): boolean {
    return window.location.href.includes("forbidden");
  }

  businessActive(): boolean {
    return (window.location.href.includes("businessLevel"));
  }

  riskActive(): boolean {
    return (window.location.href.includes("riskLevel"));
  }

  getRiskAreas() {
    this.dataService.riskareasGet().subscribe((response: any) => {
      if (response) {
        this.riskNodesArray = response;
        this.globalService.riskareaArray = response;
      }
    },
      (error: any) => {

      });
  }

  userAccess() {
    if (this.globalService.loginUserDetails.sso) {
      this.globalService.loginUserDetails.userData[0].userRoles.forEach((item: any) => {
        if (!this.globalService.loginUserDetails.isAdmin) {
          if (item.role.name == "Reader") {
            this.globalService.loginUserDetails.isReader = true;
          } else if (item.role.name == "Editor") {
            this.globalService.loginUserDetails.isEditor = true;
          }
          else if (item.role.name == "Admin") {
            this.globalService.loginUserDetails.isReader = true;
            this.globalService.loginUserDetails.isEditor = true;
          }
        }
      });
    }
  }

  goToT1IndustryPage(id: any) {
    return '/businessLevel/t1Rollups/' + id;
  }

  goToT2BusinessPage(industryid: any, businessid: any) {
    return '/businessLevel/t2Rollups/' + industryid + '/' + businessid;
  }

  requireT1UserOrAbove(industryId: any) {
    var access = this.globalService.loginUserDetails.userData[0].userRoles.find((role: any) => {
      if (role.roleId == 1 || role.roleId == 2) {
        return (role.industryId == industryId || role.industryId == 0);
      } else {
        return role;
      }
    });
    if (this.globalService.loginUserDetails.isAdmin || access) {
      return true;
    }
    else {
      return false;
    }
  }

  requireT2UserOrAbove(industryId: any, businessId: any) {
    if (this.globalService.loginUserDetails.userData[0].industryDetails.length > 1) {
      var arrayIds = this.globalService.loginUserDetails.userData[0].industryDetails;
      var mainId;
      var busId;
      var industryIds;
      for (var j = 0; j < arrayIds.length; j++) {
        if (arrayIds[j].industryId == industryId) {
          industryIds = arrayIds[j].industryId;
          mainId = arrayIds[j].businesses;
          for (var x = 0; x < mainId.length; x++) {
            if (mainId[x].businessId == businessId) {
              busId = mainId[x].businessId;
            }
          }
        }
      }
      if ((busId !== undefined
        && busId == businessId)
        && !this.globalService.loginUserDetails.isAdmin
        && industryIds == industryId) {
        return true;
      } else {
        if (this.globalService.loginUserDetails.isAdmin) {
          return true;
        }
        else {
          return false;
        }
      }
    }
    else {
      var arrays = this.globalService.loginUserDetails.userData[0].industryDetails[0];
      var selectedId;
      for (var i = 0; i < arrays.businesses.length; i++) {
        if (arrays.businesses[i].businessId == businessId) {
          selectedId = arrays.businesses[i].businessId;
        }
      }
      if ((selectedId !== undefined &&
        selectedId == businessId) &&
        !this.globalService.loginUserDetails.isAdmin &&
        this.globalService.loginUserDetails.userData[0].userRoles[0].industryId == industryId) {
        return true;
      } else {
        if (this.globalService.loginUserDetails.isAdmin) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  };

  logOut() {
    sessionStorage.clear();
    localStorage['tokenJson'] = null;
    localStorage['user_sso'] = null;
    localStorage['expireTime'] = null;
    localStorage.clear();
    location.href = `${environment.cognitoRedirectUri}/logout?client_id=${environment.cognitoClientId}&logout_uri=${environment.cognitoLogoutUri}/logoff/logoff.jsp?referrer=` + window.location.origin + "/"
  }

  userNavigateAdmin() {
    if (this.isUserAdmin) {
      return 'admin';
    }
    return null; // disable routing link if not admin
  }

}
